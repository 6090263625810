import React, {Component} from 'react';

import Moment from 'react-moment';
import './css/Clock.css';


export default class Clock extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {date: new Date()};
    }


    componentDidMount() {

        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return (
            <Moment className="Clock" element="div" format="HH:mm:ss" >{this.state.date}</Moment>

        );
    }

    /**
     * Update the date property
     */
    tick() {
        this.setState({date: new Date()});
    }
}
