import React, {Component} from 'react';
//import {Map, TileLayer, withLeaflet} from 'react-leaflet';
import {Map, TileLayer} from 'react-leaflet';
import './css/map.css';
import './css/leaflet.css';
import './css/fontawesome-free-5.7.1-web/css/all.min.css';
import Vehicles from './Vehicles';
import openSocket from 'socket.io-client';
import L from 'leaflet';
import conf from './conf.json';
import vStyle from './vStyle.json';


export default class MyMap extends Component {

    /**
     *
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.state = {
            btnAltText: conf.leaflet.small.btnAltText,
            btnSize: conf.leaflet.small.btnSize,
            lines: {},
            mode: conf.leaflet.small.mode,
            position: conf.leaflet.position,
            routes: {},
            vehicles: {},
            zoom: conf.leaflet.zoom,
        };
    }

    componentDidMount() {
        const socket = openSocket(conf.socket_server);
        socket.on('change vehicle', (vehicle) => this.updateVehicle(vehicle));
        socket.on('add vehicle', (vehicle) => this.updateVehicle(vehicle));
        socket.on('change delay', (vehicle) => this.updateVehicle(vehicle));
        socket.on('delete vehicle', (vehicle) => this.deleteVehicle(vehicle));
    }


    /**
     * Render the map using Vehicles to display the markers
     * URL: https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png
     *    id: mapbox.streets
     *  Todo: check what this "access_token" is for
     * @returns {*}
     */
    render() {
        var vehicles = this.state.vehicles;
        const routes = this.state.routes;

        /*
            url: conf.leaflet.url,
            type: 'protobuf',
            attribution: conf.leaflet.attribution,

                    <TileLayer
                        url={conf.leaflet.b_url}
                        attribution={conf.leaflet.attribution}/>
         */

        const options = {
            url: conf.leaflet.url,
            attribution: conf.leaflet.attribution,
            vectorTileLayerStyles: vStyle.layers
        };

        return (
            <div className={"mymap " + this.state.mode}>
                <Map key={this.state.mode} center={this.state.position} zoom={this.state.zoom} className="map__reactleaflet" id="mapid">
                    <TileLayer
                        url={conf.leaflet.url}
                        attribution={conf.leaflet.attribution}/>
                    <Vehicles vehicles={vehicles} routes={routes}/>
                </Map>
                <button className="btn btnSize" data-original-title="" title={this.state.btnAltText}
                        onClick={this.toggleMapSize.bind(this)}>
                    <i className={"fas " + this.state.btnSize}></i>
                </button>
            </div>);
    }

    /**
     * deleteVehicle
     * delete the given vehicle from the vehicles-array
     * @param vehicle
     */
    deleteVehicle(vehicle) {
        if (vehicle.id in this.state.vehicles) {
            var vs = this.state.vehicles;
            delete vs[vehicle.id];
            this.setState({vehicles: vs});
        }
    }

    updateRoutes(id) {
        var routes = this.state.routes;
        if (id in routes && routes[id].isComplete) {
            return;
        }
        routes[id] = {isComplete: false, wayPoints: []};
        this.setState({routes: routes});
        fetch(conf.api_server + "/route?id=" + id + "&way_point")
            .then(res => res.json())
            .then(
                (result) => {
                    var route = [];
                    result.way_points.forEach(function (wp) {
                        route.push([wp.gps_latitude, wp.gps_longitude]);
                    });
                    routes[id] = {wayPoints: route, isComplete: true};
                    this.setState({routes: routes});
                    console.log("Successfully added route=[" + id + "]. Number of points=[" + result.way_points.length + "]");
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            );
    }

    /**
     * setIcon
     * Set the appropriate icon for the given vehicle.
     * @param v
     */
    setIcon(v) {
        v.icon =
            new L.DivIcon({
                className: 'my-div-icon',
                html: `<span id="bus${v.id}" class="marker ${v.cssClass}">${v.id}</span>`,
                setZIndexOffset: 1000 // This is forceZIndex value
            });
    }

    toggleMapSize() {
        if (this.state.mode === conf.leaflet.small.mode) {
            this.setState(conf.leaflet.full);
        } else {
            this.setState(conf.leaflet.small);
        }
    }

    /**
     * updateVehicle
     * Set the properties of the given vehicle, update the vehicles-array entry or add a new one.
     * Trigger update of the routes.
     * @param vehicle
     */
    updateVehicle(vehicle) {
        this.updateRoutes(vehicle.route_id);
        if (vehicle.destination === conf.strings.transfer) {
            vehicle.type = 'transfer';
            vehicle.route_id = 0;
        } else if (vehicle.delay === 0) vehicle.type = 'intime';
        else if (vehicle.delay > 0) vehicle.type = 'late';
        else vehicle.type = 'early';
        vehicle.cssClass = 'marker-' + vehicle.type;
        var vs = this.state.vehicles;
        this.setIcon(vehicle);
        vs[vehicle.id] = vehicle;
        this.setState({vehicles: vs});
    }


}
