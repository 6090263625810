import React, {Component, Fragment} from 'react';
import {Marker, Polyline, Popup} from 'react-leaflet';
import conf from './conf.json'


export default class Vehicles extends Component {

    /**
     * Initialize the Vehicles Component.
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {activeRoute: 0, activeVehicle: 0, route: []};
    }


    /**
     * Handle mouse-move - set activeRoute if a marker is entered and clear activeRoute if
     * a marker is left.
     *
     * @param v
     * @param showRoute
     */
    handleMouseMove(v, showRoute) {
        this.setState({activeRoute: showRoute ? v.route_id : 0});
    }


    /**
     * handleClick
     * Handle a click event for the given vehicle (marker)
     * @param v
     */
    handleClick(v) {
        if (this.state.activeVehicle !== 0 && v.id === this.state.activeVehicle) {
            this.setState({activeVehicle: 0});
            return;
        }
        this.setState({activeVehicle: v.id, activeRoute: v.route_id});
    }


    /**
     * Render the vehicle markers (including potential popup)
     * and the "active" route - if there is any
     * @returns {*}
     */
    render() {
        const vehicles = this.props.vehicles;
        return (
            <Fragment>
                {Object.keys(vehicles).map((key) => this.renderVehicleMarker(vehicles[key]))}
                {this.renderRoute()}
            </Fragment>
        );
    }

    /**
     * Return the render-output of the renderPopup for the given vehicle if it is "active".
     * @param vehicle
     * @returns {*}
     */
    renderPopup(vehicle) {
        if (this.state.activeVehicle === 0 || vehicle === null || this.state.activeVehicle !== vehicle.id)
            return "";
        var line = vehicle.line_name;
        if (line.indexOf(conf.strings.line) !== -1) {
            line = line.replace(conf.strings.line, '');
        }

        var cssClassDelay = "popup-" + vehicle.type;
        var delay = conf.strings.intime;
        if (vehicle.delay < 0) {
            delay = vehicle.delay + " Min.";
        } else if (vehicle.delay > 0) {
            delay = "+ " + vehicle.delay + " Min.";
        }
        // for transfer drives
        if (vehicle.destination === conf.strings.transfer) {
            return (
                <Popup position={[vehicle.latitude, vehicle.longitude]}>
                    <b>Richtung</b>: {conf.strings.transfer}
                </Popup>
            );
        }
        return (
            <Popup position={[vehicle.latitude, vehicle.longitude]}
                   onClose={this.handleMouseMove.bind(this, vehicle.route_id, false)}>
                <b>{conf.strings.line}</b>: {line}<br/>
                <b>Richtung</b>: {vehicle.destination}<br/>
                <b>Status</b>: <span
                className={cssClassDelay}>{delay}</span><br/>
                <b>Passagiere</b>: {vehicle.fill_abs}
            </Popup>
        );
    }


    /**
     * Return the render-output for the active route.
     * Representation options for Polyline are taken from conf.json
     *
     * @returns {*}
     */
    renderRoute() {
        if (this.state.activeRoute === 0) return "";
        const id = this.state.activeRoute;
        const route = this.props.routes[id];
        if (!route.isComplete) {
            console.log("Route with id=[" + id + "] is not yet available");
            return "";
        }

        return (
            <Polyline key={"route_" + id}
                      positions={route.wayPoints}
                      color={conf.route.color}
                      weight={conf.route.weight}
                      opacity={conf.route.opacity}
            />
        );
    }

    /**
     * renderVehicleMarker
     * Return a Marker for rendering of the given vehicle.
     * Each fragment has the vehicle number as key (for React)
     * @param v
     * @returns {*}
     */
    renderVehicleMarker(v) {
        return (
            <Fragment key={v.id}>
                <Marker
                    className={v.cssClass}
                    position={[v.latitude, v.longitude]}
                    icon={v.icon}
                    onClick={this.handleClick.bind(this, v)}
                    onMouseOver={this.handleMouseMove.bind(this, v, true)}
                    onMouseOut={this.handleMouseMove.bind(this, v, false)}>
                </Marker>
                {this.renderPopup(v)}
            </Fragment>
        );
    }


}