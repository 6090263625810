import React, {Component, Fragment} from 'react';
import './css/App.css';
import './css/family_font_loto.css';
import MyMap from './MyMap.js';
import Clock from './Clock.js';

class App extends Component {

    render() {
        return (
            <Fragment>
                <h1>
                    <div className="headline-text"><i className="fa fa-crosshairs" aria-hidden="true"></i> <span
                        className="headline">LIVE-KARTE</span><p></p></div>
                </h1>
                <h1 class="headline-text"><i className="fa fa-crosshairs" aria-hidden="true"></i> <span>Live-Karte</span></h1>
                <h2>Mit Bussen?</h2>
                <MyMap/>
            </Fragment>
        );
    }
}



export default App;
